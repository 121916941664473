import React from 'react'
import { graphql, withPrefix } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/CardProduct'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import Img from 'gatsby-image'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1
}

const BgImg = styled(Img) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  min-height: 350px;
  max-height: 50vh;
  height: auto;
  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`
const DivBgImg2Parent = styled.div`
  width: 300px;
  height: 300px;
  margin: 10px;
  color: white;
  overflow: hidden;
  a {
  text-decoration: none;
  color: inherit; 
  }
`

const BgImg2 = styled(BackgroundImage) `
height: 100%;
width: 100%;
line-height: 300px;
text-align: center;
.span {
display: inline-block;
vertical-align: middle;
line-height: normal;
}
:hover {
    -webkit-transform: scale(1.5,1.5);
    -webkit-transition: all 0.3s ease-in-out;
}
`

const SliderOverlay = styled.div`
  color: white; 
  position: absolute;
  top: 170px;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  font-size: 3em;
  z-index:10;
  span {
    font-size: 0.4em;
  }
`

const BgImg3 = styled.img `
  width: 100px;
  height: 100px;
  line-height: 300px;
  margin: 10px;
  color: white;
  text-align: center;
  .span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
`

const Glass = styled.div`
	.glass{
    /* background styles */
	position: relative;
	display: inline-block;
	padding: 15px 25px;
	background-color: grey; /*for compatibility with older browsers*/
	background-image: linear-gradient(grey,black);

	/* text styles */
	text-decoration: none;
	color: #fff;
	font-size: 25px;
	font-family: sans-serif;
    border-radius: 3px;
    box-shadow: 0px 1px 4px -2px #333;
    text-shadow: 0px -1px #333;
	font-weight: 100;
    }
    .glass:after{
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: calc(100% - 4px);
	height: 50%;
	background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.2));
   }
}`

const FlexContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  font-size: 1.4em;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const FlexContainer2 = styled.div`
  margin-top: 50px;
  border: 1px black;
  border-radius: 10px;
  display: flex;
  font: 25px bold;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`
const TextDiv = styled.div`
  padding-top: 20px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 20pt;
  font-weight:  bold;
`

const Slider2 = styled(Slider) `
  background-color: #F8F8F8;
`

const SpanTestimonial = styled.span `
  font-weight: normal;
   font-size: 15px;
`

const SocMedImage = styled.img`
  width: 80px;
  height: 80px;
  :hover {
    -webkit-transform: scale(1.5,1.5);
    -webkit-transition: all 0.3s ease-in-out;
  } 
  @media only screen and (max-width: 600px) {
    width: 50px;
    height: 50px;
  }
`

const Index = ({ data }) => {
  const posts = data.allContentfulKiwimCarousel.edges
  const products = data.allContentfulKiwimProducts.edges
  const testimonials = data.allContentfulKiwimTestimonials.edges
  const featuredPost = posts[0].node
    const icons = [{ "img": "icoColors.jpeg" },
        { "img": "icoFlavors.jpeg" },
        { "img": "icoNatural.jpeg" },
        { "img": "icoPreserve.jpeg" },
        { "img": "icoOil.jpeg" }
    ]
  return (
    <Layout>
      <SEO />
      { (
        <Helmet>
          <title>{`${config.siteTitle}`}</title>
        </Helmet>
          )}
          <div style={{ width: "100%", position: "relative" }}>
              <SliderOverlay className="sliderOverlay">Customized Laser Engraving <br /><span className="accentColor"> by Apapun Engrave</span></SliderOverlay >     
          <BgImg fluid={posts[0].node.image.fluid} heigth={'20vh'} />
              <FlexContainer>
                  <div style={{ margin: "15px" }} className="accentColor">
                      <div style={{ display: "flex", "justify-content": "center" }}>
                          <a href={withPrefix("work")} className="glass">
                            <SocMedImage style={{ bottom: '3em' }} src={withPrefix("work.png")}>
                            </SocMedImage>
                          </a>
                      </div>
                      <br /><br />
                      Portfolio
                  </div>
          </FlexContainer>
      </div>
    </Layout>
  )
}
  /*
             <TextDiv>
              <span> What you need to know about KIWIM </span>
          </TextDiv>
          <FlexContainer2>
              {icons.map((post) => (
                  <BgImg3 src={withPrefix(post.img)} backgroundColor={'#eeeeee'} >
                  </BgImg3>
              ))}
          </FlexContainer2>
      <Container>
        {isFirstPage ? (
          <CardList>
            <Card {...featuredPost} featured />
            {posts.slice(1).map(({ node: post }) => (
              <Card key={post.order} {...post} />
            ))}
          </CardList>
        ) : (
          <CardList>
            {posts.map(({ node: post }) => (
              <Card key={post.order} {...post} />
            ))}
          </CardList>
        )}
      </Container>
          <Pagination context={pageContext} />
      */
export const query = graphql`
  query MyQuery {
  allContentfulKiwimCarousel(limit: 10, sort: {fields: order}) {
    edges {
      node {
        image {
          fluid(maxWidth: 1800,maxHeight: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
  allContentfulKiwimProducts(limit: 10, sort: { fields: updatedAt }) {
    edges {
      node {
        id
        heroImage {
          fluid(maxWidth: 1800, , maxHeight: 800) {
            ...GatsbyContentfulFluid
          }
        }
        name
        slug
      }
    }
  }
  allContentfulKiwimTestimonials(limit: 10, sort: { fields: updatedAt }) {
    edges {
      node {
        userName
        testimonial
      }
    }
  }
}
`


export default Index
